import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

export default function initializeFirebase() {
	const firebaseConfig = {
		apiKey: process.env.FIREBASE_API_KEY,
		authDomain: process.env.FIREBASE_AUTH_DOMAIN,
		databaseURL: process.env.FIREBASE_DATABASE_URL,
		projectId: process.env.FIREBASE_PROJECT_ID,
		storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.FIREBASE_APP_ID,
		measurementId: process.env.FIREBASE_MEASUREMENT_ID,
	};

	firebase.initializeApp(firebaseConfig);

	if (process.env.NODE_ENV === 'production') {
		import('firebase/analytics').then(() => {
			firebase.analytics();
		});
		import('firebase/performance').then(() => {
			firebase.performance();
		});
	}

	firebase
		.firestore()
		.enablePersistence()
		.catch((err) => console.error('Error enabling firestore persistence', err));
}
