import { useQuery } from 'react-query';
import firebase from 'firebase/app';
import { Pricing } from './lib/data/pricing';

export type CompanyDetails = {
	name: string;
	address: string;
	city: string;
	state: string;
	zip: string;
};

export type UserData = {
	pricing: Pricing;
	companyDetails: CompanyDetails;
};

export default function useUserData(config = {}) {
	const defaultConfig = {
		staleTime: 5 * 60 * 1000,
		enabled: firebase.auth().currentUser,
	};

	async function fetchUserData() {
		const { uid } = firebase.auth().currentUser;
		const userSnapshot = await firebase.firestore().doc(`/users/${uid}`).get();
		const data = userSnapshot.data() as UserData;
		return data;
	}

	return useQuery('userData', fetchUserData, {
		...defaultConfig,
		...config,
	});
}
