import { useState } from 'react';
import { debounce, toNumber } from 'lodash';
import firebase from 'firebase/app';

import { useMutation, queryCache } from 'react-query';

import useUserData from '../useUserData';

import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
	DialogTitle,
	InputAdornment,
	Typography,
} from '@material-ui/core';

function useHourlyRateForm() {
	const [open, setOpen] = useState(false);
	const [hourlyRate, setHourlyRate] = useState();

	const { status } = useUserData({
		staleTime: Infinity,
		onSuccess: (userData) => {
			if (!userData?.pricing?.hourlyRate) {
				setOpen(true);
			}
		},
	});

	const [saveHourlyRateToServer, { status: savingStatus }] = useMutation(
		async function saveHourlyRateToServer(hourlyRate) {
			const { uid } = firebase.auth().currentUser;
			await firebase
				.firestore()
				.doc(`/users/${uid}`)
				.set({
					pricing: {
						hourlyRate: hourlyRate ? toNumber(hourlyRate) : hourlyRate,
					},
				});
		},
		{
			onSuccess: (_, hourlyRate) => {
				queryCache.setQueryData(
					'userData',
					(
						userData: { pricing: { hourlyRate?: number } } = { pricing: {} }
					) => {
						const result = {
							...userData,
							pricing: {
								...userData.pricing,
								hourlyRate,
							},
						};
						return result;
					}
				);
			},
		}
	);

	const debouncedSaveHourlyRateToServer = debounce(
		saveHourlyRateToServer,
		1000
	);

	function setHourlyRateWithServer(hourlyRate) {
		setHourlyRate(hourlyRate);
		debouncedSaveHourlyRateToServer(hourlyRate);
	}

	return {
		open,
		setOpen,
		hourlyRate,
		setHourlyRateWithServer,
		status,
		savingStatus,
	};
}

export default function HourlyRateDialog() {
	const { open, setOpen, hourlyRate, setHourlyRateWithServer, status } =
		useHourlyRateForm();

	return (
		<Dialog open={open}>
			<DialogTitle>Welcome! To get started, enter your hourly rate</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<TextField
						label="Hourly rate"
						type="number"
						id="hourlyRate"
						variant="filled"
						helperText="How much do you charge per hour?"
						value={hourlyRate}
						disabled={status !== 'success'}
						fullWidth
						onChange={(event) => {
							setHourlyRateWithServer(event.target.value);
						}}
						inputProps={{
							min: '1',
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">$</InputAdornment>
							),
							endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
						}}
					/>
				</DialogContentText>
				<Typography paragraph>
					You can change your hourly rate at any time in the pricing
					customization screen.
				</Typography>
				<DialogActions>
					<Button
						color="primary"
						disabled={status !== 'success' || Number(hourlyRate ?? 0) <= 0}
						onClick={() => {
							setOpen(false);
						}}
					>
						Done
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}
