import { lazy, useState, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query-devtools';
import clsx from 'clsx';
import _debounce from 'lodash/debounce';

import firebase from 'firebase/app';

import * as Fullstory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import amplitude from 'amplitude-js';

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';

import { Fade, CircularProgress, Snackbar, Container } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import initializeFirebase from './initializeFirebase';

import BottomNavigation from './components/BottomNavigation';
import HourlyRateDialog from './components/HourlyRateDialog';
import useSendWelcomeEmail from './lib/bootstrap/useSendWelcomeEmail';

const SentryRoute = Sentry.withSentryRouting(Route);

const Login = lazy(() => import('./components/Login'));
const Customization = lazy(() =>
	import('./components/customization/Customization')
);
const More = lazy(() => import('./components/more/More'));
const Estimates = lazy(() => import('./components/estimates/estimates'));
const EstimateContainer = lazy(() =>
	import('./components/estimate/EstimateContainer')
);
const Pricing = lazy(() =>
	import('./components/customization/pricing/Pricing')
);
const ErrorComponent = lazy(() => import('./components/ErrorComponent'));

if (process.env.NODE_ENV === 'production') {
	Fullstory.init({ orgId: process.env.FULLSTORY_ORG_ID });
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		integrations: [new BrowserTracing()],
	});
	amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY);
}

// eslint-disable-next-line no-unused-expressions
import('./registerServiceWorker');

initializeFirebase();

const useStyles = makeStyles((theme) => ({
	progress: {
		margin: 'auto',
		display: 'block',
		marginTop: '10rem',
	},
	snackbar: {
		[theme.breakpoints.down('xs')]: {
			bottom: 90,
		},
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	main: {
		flexGrow: 1,
		overflowY: 'scroll',
	},
}));

function useProperHeightForIos() {
	useEffect(() => {
		function setProperHeightForIos() {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}

		const debouncedEventHandler = _debounce(setProperHeightForIos, 500);
		setProperHeightForIos();

		window.addEventListener('resize', debouncedEventHandler);
		return () => {
			window.removeEventListener('resize', debouncedEventHandler);
		};
	}, []);
}

function useTestErrors() {
	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			document.addEventListener('keydown', (event) => {
				if (event.ctrlKey && event.key === 'e') {
					throw Error('This is a test error generated by pressing ctrl + e');
				}
			});
		}
	});
}

function useUser() {
	const [user, setUser] = useState(null);
	const [status, setStatus] = useState('loading');

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			setUser(user);
			if (user) {
				if (process.env.NODE_ENV === 'production') {
					Fullstory.identify(user.uid, {
						displayName: user.displayName,
						email: user.email,
					});
					Sentry.configureScope((scope) => {
						scope.setUser({ email: user.email, name: user.displayName });
					});
					amplitude.getInstance().logEvent('Test event');
				}
			}
			setStatus('loaded');
		});
	}, []);

	return { user, status };
}

function useOfflineStatus(classes) {
	const [offline, setOffline] = useState(!navigator.onLine);
	useEffect(() => {
		function updateOnlineStatus() {
			setOffline(!navigator.onLine);
		}

		window.addEventListener('online', updateOnlineStatus);
		window.addEventListener('offline', updateOnlineStatus);

		return () => {
			window.removeEventListener('online', updateOnlineStatus);
			window.removeEventListener('offline', updateOnlineStatus);
		};
	}, []);

	function OfflineSnackbar() {
		return (
			<Snackbar
				open={offline}
				classes={{ root: classes }}
				message="No internet connection"
			/>
		);
	}

	return OfflineSnackbar;
}

function Main() {
	const classes = useStyles();

	const { user, status: userStatus } = useUser();

	useSendWelcomeEmail(user);
	useProperHeightForIos();
	useTestErrors();

	const OfflineSnackbar = useOfflineStatus(classes.snackbar);

	return (
		<Suspense
			fallback={
				<Fade in appear style={{ transitionDelay: '800ms' }} unmountOnExit>
					<CircularProgress classes={{ root: classes.progress }} />
				</Fade>
			}
		>
			<Sentry.ErrorBoundary fallback={() => <ErrorComponent />} showDialog>
				{userStatus === 'loaded' ? (
					user ? (
						<Router>
							<Switch>
								<SentryRoute
									path="/estimates/:estimateId/invoice"
									component={EstimateContainer}
								/>
								<Container
									maxWidth="md"
									disableGutters
									className={clsx(classes.container, 'container-fix')}
								>
									<div className={`${classes.main} scroll-container`}>
										<Switch>
											<SentryRoute
												path="/estimates/:estimateId/areas/:areaIndex/line-items/:lineItemsIndex"
												component={EstimateContainer}
											/>
											<SentryRoute
												path="/estimates/:estimateId/areas/:areaIndex"
												component={EstimateContainer}
											/>
											<SentryRoute
												path="/estimates/:estimateId"
												component={EstimateContainer}
											/>
											<SentryRoute path="/estimates" component={Estimates} />
											<SentryRoute
												path="/customization/pricing"
												component={Pricing}
											/>
											<SentryRoute
												path="/customization"
												component={Customization}
											/>
											<SentryRoute path="/more" component={More} />
											<Redirect path="/" to="/estimates" />
										</Switch>
										<OfflineSnackbar />
									</div>
									<HourlyRateDialog />
									<BottomNavigation />
									<ReactQueryDevtools />
								</Container>
							</Switch>
						</Router>
					) : (
						<Login />
					)
				) : null}
			</Sentry.ErrorBoundary>
		</Suspense>
	);
}

ReactDOM.render(<Main />, document.getElementById('main'));
