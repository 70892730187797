import { useLocation } from 'react-router-dom';

import MuiBottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import LinkWithRef from './LinkWithRef';

export default function BottomNavigation() {
	const { pathname } = useLocation();

	const regexResult = /^\/\w+/.exec(pathname);
	const firstPart = regexResult && regexResult[0];

	return (
		<MuiBottomNavigation showLabels value={firstPart}>
			<BottomNavigationAction
				component={LinkWithRef}
				label="Estimates"
				icon={<AssignmentIcon />}
				to="/estimates"
				value="/estimates"
			/>
			<BottomNavigationAction
				component={LinkWithRef}
				label="Customization"
				icon={<SettingsIcon />}
				to="/customization"
				value="/customization"
			/>
			<BottomNavigationAction
				component={LinkWithRef}
				label="More"
				icon={<MoreVertIcon />}
				to="/more"
				value="/more"
			/>
		</MuiBottomNavigation>
	);
}
