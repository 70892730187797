import { useEffect } from 'react';
import firebase from 'firebase/app';
import useUserData from '../../useUserData';

export default function useSendWelcomeEmail(user) {
	const { status: userDataStatus, data: userData } = useUserData();

	useEffect(() => {
		if (userDataStatus === 'success' && !userData) {
			const sendWelcomeEmail = firebase
				.functions()
				.httpsCallable('sendWelcomeEmail');
			const { displayName, email } = user;
			sendWelcomeEmail({ displayName, email }).catch((err) => {
				console.error(err);
			});
		}
	}, [user, userData, userDataStatus]);
}
